import { Col, Row } from "antd";
import React from "react";
import "./global.css";
import VermiCompost from "../src/Images/Compost.jpeg";
import crop from "../src/Images/Crop.jpeg";
const Organic = () => {
  return (
    <div>
      <Row>
        <Col
          lg={24}
          sm={24}
          md={24}
          style={{ backgroundColor: "white", width: "100%" }}
        >
          <div>
            {" "}
            <h1
              className="whyOrganicHeading"
              style={{
                fontSize: "50px",
                color: "#c9b377",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Organicaly Grown
            </h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} sm={24} md={24}>
          <section className="OrganicSection1">
            <img
              className="OrganicSection1Img"
              src={crop}
              style={{
                marginLeft: "50px",
                borderRadius: "20px",
              }}
            />
          </section>
        </Col>
      </Row>
      <Row>
        <Col lg={24} sm={24} md={24}>
          <section className="OrganicDescription">
            <ul
              style={{
                fontSize: "20px",

                marginLeft: "10px",
                marginRight: "30px",
              }}
            >
              Why Organically grown Experience the Natural Difference! At Pujari
              & Family Farm Rice, we take pride in growing our paddy rice
              organically, the way nature intended. Our commitment to
              sustainable farming practices means you can trust that our rice is
              free from synthetic chemicals, pesticides, and GMOs. For detailed
              test reports and certificates confirming the purity of our organic
              paddy rice, please visit our 'Certificates' tab. Why choose our
              Farm Rice? Our Farm Rice is grown with your well-being in mind.
              It's a wholesome, nutritious choice for you and your family. By
              choosing our organic rice, you support eco-friendly farming
              practices that promote soil health, protect biodiversity, and
              reduce harm to the environment. Taste the difference! Our organic
              paddy rice has a pure, natural flavor that you won't find in
              conventionally grown rice. We are dedicated to sustainable
              agriculture, ensuring that future generations can enjoy the
              benefits of our fertile land. Make a positive choice for your
              health, the environment, and future generations. Choose Pujari &
              Family Farm Rice. Join us in cultivating a greener and healthier
              world, one grain at a time.
            </ul>
          </section>
        </Col>
      </Row>
      <Row>
        <Col lg={24} sm={24} md={24}>
          <section className="OrganicSection1">
            <img
              className="OrganicSection1Img"
              src="https://www.colorado.edu/ecenter/sites/default/files/styles/large/public/callout/image3.png?itok=NVSZcy4h"
              alt=""
              width="1300px"
              style={{ marginLeft: "50px", borderRadius: "20px" }}
            />
          </section>
        </Col>
      </Row>
    </div>
  );
};

export default Organic;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import StepsWeFollow from "./StepsWeFollow";
import LowGiRice from "./LowGiRice";
import PressAndMedia from "./PressAndMedia";
import Certificates from "./Certificates";
import "./global.css";
import Organic from "./Organic";

const App = () => {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/steps-we-follow" element={<StepsWeFollow />} />
            <Route path="/low-gi-rice" element={<LowGiRice />} />
            <Route path="/press-and-media" element={<PressAndMedia />} />
            <Route path="/organic" element={<Organic />} />
            <Route path="/certificates" element={<Certificates />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default App;

import React from "react";
import paddyImage from "./Images/PaddingPlanting.jpg";
import vermiComposite from "./Images/Vermicompost.jpeg";
import LandPreparation from "./Images/Ploughing.jpeg";
import Harvesting from "./Images/Cutter.jpeg";
import Trichoderma from "./Images/Trichoderma.jpeg";
import Aged from "./Images/Aged Shed.jpeg";

import "./StepsWeFollow.css";
const StepsWeFollow = () => {
  return (
    <div>
      <div className="stepsWeFollowHeading">
        <h1 style={{ color: "#c9b377" }}>Crop monitoring and management</h1>
      </div>
      <div className="stepsWeFollow">
        <div className="stepsWeFollowLandPrepartion">
          <section>
            <h3 style={{ alignItems: "flex-start", color: "#c9b377" }}>
              Land preparation:
            </h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img src={LandPreparation} alt="" width="350px" height="250px" />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2a">
              <ul>
                <li>
                  Dhaincha "We use Dhaincha as a green manure cover crop to
                  improve soil fertility and suppress weeds. Dhaincha enriches
                  the soil with nitrogen through a symbiotic relationship with
                  nitrogen-fixing bacteria. After 60-90 days of growth, we cut
                  the plants and then use a rotavator for thorough incorporation
                  into the soil. This practice enhances soil fertility,
                  promoting healthier crops." After Dhaincha has decomposed(2 to
                  4 week), The soil should be well-tilled, free from clumps, and
                  have a fine texture.
                </li>
              </ul>
            </p>
          </section>
        </div>
        <div className="stepsWeFollowDescriptionDiv">
          <section>
            <h3 style={{ color: "#c9b377" }}>Seed selection</h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img
              src="https://img1.wsimg.com/isteam/stock/126294/:/cr=t:12.66%25,l:0%25,w:100%25,h:74.69%25/rs=w:388,h:194,cg:true"
              alt=""
              width="350px"
              height="250px"
            />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              <ul>
                Since major benefits of Low GI Sona(RNR) Like
                <br />
                <li>Blood Sugar Control Satiety</li>
                <li>Heart Health</li>
                <li>Weight Management</li>
                <li>Improved Energy Levels</li>
                <li>Better Overall Nutrition and many more</li>
                thats why we are using Low GI Sona(RNR) for our customers.
              </ul>
            </p>
          </section>
        </div>
        <div className="stepsWeFollowDescriptionDiv">
          <section>
            <h3 style={{ color: "#c9b377" }}>Seeds Treatment:</h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img src={Trichoderma} alt="" width="350px" height="250px" />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              {" "}
              <ul>
                <li>
                  Trichoderma and Pseudomonas <br></br>(Powder will collect from
                  KVK Raichur) <br></br> We are employing a combined Pseudomonas
                  and Trichoderma bath for dipping rice roots before planting.
                  This practice collectively enhance plant health, suppress
                  diseases, stimulate root growth, improve nutrient uptake, and
                  promote overall crop productivity in an environmentally
                  sustainable way.
                </li>
              </ul>
            </p>
          </section>
        </div>
        <div className="stepsWeFollowDescriptionDivPlanting">
          <section>
            <h3 style={{ color: "#c9b377", alignItems: "center" }}>Planting</h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img src={paddyImage} alt="" width="350px" height="250px" />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              <h4 style={{ color: "#c9b377" }}></h4>{" "}
              <ul>
                <li>
                  Transplanting: Grow rice seedlings in a separate nursery field
                  until they are about 4 to 6 weeks old.Transplant the healthy
                  seedlings into the main field, maintaining proper spacing
                  between plants.
                </li>
              </ul>{" "}
            </p>
          </section>
        </div>
        <div className="stepsWeFollowDescriptionDivFertilization">
          <section>
            <h3
              style={{
                color: "#c9b377",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Fertilization
            </h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img src={vermiComposite} alt="" width="350px" height="250px" />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              {" "}
              <ul type="upper-alpha">
                <li>Dhaincha (green manure) </li>
                <li>Tippe Gobra (Farm Yard</li>
                <li>
                  {" "}
                  Manure - FYM) Vermicompost Compost Azospirillum
                  (nitrogen-fixing bacteria)
                </li>
                <li> Jeevamruta (microbial fertilizer)</li>
                <li> Gana Jeevamruta (multi-microbial consortium)</li>
                <li> Go Krupmrut (organic microbial fertilizer)</li>
                <li> OWDC (Organic Waste Decomposer)</li>
              </ul>
            </p>
          </section>
        </div>
        <div className="stepsWeFollowDescriptionIrrigation">
          <section>
            <h3
              style={{
                color: "#c9b377",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Irrigation:
            </h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img
              src="https://c8.alamy.com/comp/AR8EEW/irrigation-water-channel-through-a-rice-paddy-in-india-AR8EEW.jpg"
              alt=""
              width="350px"
              height="250px"
            />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              {" "}
              <ul>
                <li>
                  Maintain adequate water levels in the rice field, conventional
                  rice farming commonly uses flooding or continuous standing
                  water throughout the cultivation period.
                </li>
              </ul>
            </p>
          </section>
        </div>
        <div className="stepsWeFollowDescriptionWeed">
          <section>
            <h3
              style={{
                color: "#c9b377",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Weed control
            </h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img
              src="https://bl-i.thgim.com/public/incoming/sy8b8f/article66861599.ece/alternates/FREE_1200/IMG_iStock-1305837094_2_1_HCA5MPNG.jpg"
              alt=""
              width="350px"
              height="250px"
            />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              <ul>
                <li>
                  Bioherbicides.<br></br>
                  <li>
                    Crop Rotation Sesbania (Dhaincha) is grown between rice
                    crops.
                  </li>
                  <li>Proper Water Management and Manual Weed Removal</li>
                </li>
              </ul>
            </p>
          </section>
        </div>
        <div className="stepsWeFollowDescriptionpest">
          <section>
            <h3
              style={{
                color: "#c9b377",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Pest and disease management:
            </h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img
              src="https://cdn.downtoearth.org.in/library/large/2017-11-07/0.59063000_1510059407_pesticide-3--meeta-ahlawat---cse.jpg"
              alt=""
              width="350px"
              height="250px"
            />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              <ul>
                <li>
                  <li>Trichoderma (beneficial fungi)</li>
                  <li> Pseudomonas (beneficial bacteria)</li>

                  <li>Neem Oil (potentially used for pest control)</li>
                </li>
              </ul>
            </p>
          </section>
        </div>

        <div className="stepsWeFollowDescriptionHar">
          <section>
            <h3
              style={{
                color: "#c9b377",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Harvesting
            </h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img src={Harvesting} alt="" width="350px" height="250px" />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              <ul>
                <li>
                  Harvest the rice crop when the grains have matured and reached
                  the desired moisture content and then further drying the
                  harvested crop around 14% moisture than packed to 75kg gunny
                  bag and ship to the cold storage.
                </li>
              </ul>
            </p>
          </section>
        </div>
        <div className="stepsWeFollowDescriptionAged">
          <section>
            <h3
              style={{
                color: "#c9b377",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Aged (stacked in Godown)
            </h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img src={Aged} alt="" width="350px" height="250px" />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              <ul>
                <li>
                  <li>Better Cooking Characteristics</li>
                  <li>Enhanced Flavour</li>
                  <li> Improved Texture Content </li>
                  <li>Reduced Moisture</li>
                  <li>Less Clumping</li>
                </li>
              </ul>
            </p>
          </section>
        </div>
        <div className="stepsWeFollowDescriptionDiva">
          <section>
            <h3
              style={{
                color: "#c9b377",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Milled And Packaged
            </h3>
          </section>
          <section className="stepsWeFollowImgSection">
            <img
              src="https://img1.wsimg.com/isteam/stock/naakypp/:/cr=t:11.3%25,l:0%25,w:100%25,h:77.4%25/rs=w:388,h:194,cg:true"
              alt=""
              width="350px"
              height="250px"
            />
          </section>
          <section className="stepsWeFollowContentSection">
            <p className="stepsWeFollowContentSectionPara1"></p>
            <p className="stepsWeFollowContentSectionPara2">
              <ol>
                <li>
                  Milled and packed to 26 kg bags based on the order we receive
                  and shipped to the customers
                </li>
              </ol>
            </p>
          </section>
        </div>
      </div>
      <div style={{ height: "100px" }} className="stepWeFollowCopyRightDiv">
        <div style={{ height: "50px" }}>
          <h3 style={{ color: "#c9b377" }}>Pujari & Family</h3>
        </div>
        <div>
          <p>Copyright © 2023 Pujari & Family - All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default StepsWeFollow;

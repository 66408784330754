import React from "react";
import { Row, Col } from "antd";
//import csss file
import "./global.css";
import PaddyPlanting from "./Images/PaddingPlanting.jpg";
import Father from "./Images/Father.jpg";
import crop from "./Images/Crop.jpeg";

const About = () => {
  return (
    <div>
      <h1
        style={{
          alignItems: "center",
          fontFamily: "sans-serif",
          marginLeft: "40px",
          display: "flex",
          justifyContent: "center",
          color: "#c9b377",
        }}
      >
        About
      </h1>

      <Row justify="space-around">
        <Col xs={24} xl={24}>
          <section
            className="AboutSection1"
            style={{
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0 0 1px grey",
              margin: "10px",
              marginTop: "0px",
            }}
          >
            <h1
              style={{
                fontSize: "26px",
                textAlign: "center",
                color: "#c9b377",
              }}
            >
              Pujari & Family
            </h1>
            <ul>
              <li style={{ fontSize: "20px" }}>
                Welcome to Pujari & Family Farm Rice, a family-run venture
                rooted in generations of farming expertise. Our passion for
                sustainable agriculture and commitment to providing customers
                with the finest quality organically grown rice sets us apart.
              </li>
              <li style={{ fontSize: "20px" }}>
                At Pujari & Family Farm Rice, we are dedicated to cultivating
                rice using organically grown methods. We firmly believe that by
                working in harmony with nature, we can produce food that is not
                only delicious but also healthier for our bodies and the
                environment.
              </li>
              <li style={{ fontSize: "20px" }}>
                Our rice is grown without the use of synthetic fertilizers or
                harmful pesticides, ensuring that every grain carries the
                natural goodness that nature intended.
              </li>
              <li style={{ fontSize: "20px" }}>
                We take pride in personally overseeing every step of the rice
                cultivation process, from planting the seeds to harvesting and
                processing. By eliminating intermediaries, we can directly
                deliver our premium rice to your doorstep, ensuring maximum
                freshness and traceability.
              </li>
              <li style={{ fontSize: "20px" }}>
                With Pujari & Family Farm Rice, you can savor the authentic
                flavors of our carefully nurtured grains while supporting local
                farmers. By choosing Pujari & Family Farm Rice, you are not just
                purchasing rice; you are joining us on a sustainable journey.
              </li>
              <li style={{ fontSize: "20px" }}>
                Together, we can make a positive impact on the environment,
                support ethical farming practices, and nourish our bodies with
                wholesome, organically grown food.
              </li>
            </ul>

            <Col xs={24} md={24} xl={24} sm={24}>
              <section
                className="aboutimg"
                style={{
                  height: "500px",
                  width: "1100px",

                  padding: "20px",

                  alignItems: "center",
                  textAlign: "center",
                  margin: "20px",
                }}
              >
                <img
                  className="FatherImage"
                  src={crop}
                  alt="pic"
                  style={{
                    width: "900px",
                    height: "1000px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "180px",
                    borderRadius: "30px",
                  }}
                />
              </section>
            </Col>
          </section>
        </Col>
      </Row>
    </div>
  );
};

export default About;

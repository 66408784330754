import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import "./global.css";
import brand from "../src/Images/brand.jpg";

const Header = () => {
  const { SubMenu } = Menu;
  const { Sider } = Layout;

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const items = [
    { label: "Home", link: "/" },
    { label: "About", link: "/about" },
    { label: "Steps We Follow", link: "/steps-we-follow" },
    { label: "Low GI Rice", link: "/low-gi-rice" },
    { label: "PRESS AND MEDIA", link: "/press-and-media" },
    { label: "Contact", link: "/contact" },
    { label: "Certificates", link: "/Certificates" },
  ];
  return (
    <div>
      <div>
        <header className="header">
          <div className="mobile">
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    width: "150px",
                    height: "70px",
                    marginLeft: "20px",

                    borderRadius: "20px",
                  }}
                  src={brand}
                  alt=""
                />
                <div>
                  <img
                    src="https://cdn-icons-png.flaticon.com/128/10080/10080458.png"
                    alt=""
                    height={"30px"}
                    onClick={toggleCollapsed}
                  />
                </div>
              </div>
              <Layout>
                <Sider
                  breakpoint="lg"
                  collapsedWidth="0"
                  onBreakpoint={(broken) => {
                    console.log(broken);
                  }}
                  onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                  }}
                >
                  <Menu
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={collapsed}
                    style={{ width: "100%" }}
                  >
                    <Menu.Item key="1" icon={<i class="fas fa-home"></i>}>
                      <Link className="Link" to="/">
                        Home
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      icon={<i class="fas fa-info-circle"></i>}
                    >
                      <Link className="Link" to="/about">
                        About
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<i class="fas fa-leaf"></i>}>
                      <Link className="Link" to="/steps-we-follow">
                        Steps we Follow
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<i class="fas fa-seedling"></i>}>
                      <Link className="Link" to="/low-gi-rice">
                        Low GI Rice
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<i class="fas fa-camera"></i>}>
                      <Link className="Link" to="/press-and-media">
                        Photo Library And Info
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<i class="fas fa-phone-alt"></i>}>
                      <Link className="Link" to="/contact">
                        Contact
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7">
                      <Link className="Link" to="/Organic">
                        WhyOrganic
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="8">
                      <Link className="Link" to="/Certificates">
                        Certificates
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Sider>
              </Layout>
            </div>
          </div>
          <div className="LargerScreen">
            <nav
              style={{
                display: "flex",

                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                style={{
                  width: "150px",
                  height: "70px",
                  marginLeft: "20px",
                  marginRight: "350px",

                  borderRadius: "20px",
                }}
                src={brand}
                alt=""
              />
              <ul className="components">
                <li>
                  <Link className="Link" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="Link" to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link className="Link" to="/steps-we-follow">
                    Steps We Follow
                  </Link>
                </li>
                <li>
                  <Link className="Link" to="/low-gi-rice">
                    Low GI Rice
                  </Link>
                </li>
                <li>
                  <Link className="Link" to="/press-and-media">
                    Photo Library And Info
                  </Link>
                </li>

                <li>
                  <Link className="Link" to="/Certificates">
                    Certificate
                  </Link>
                </li>
                <li>
                  <Link className="Link" to="/Organic">
                    Organically Grown
                  </Link>
                </li>
                <li>
                  <Link className="Link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </div>
      <div></div>
    </div>
  );
};

export default Header;

import React from "react";
import "../src/Contact.css";
import { Button, Form, Input, Select, message, Row, Col } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import {
  ContactsOutlined,
  PhoneOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
const Contact = () => {
  return (
    <div>
      <h1
        style={{
          fontSize: "70px",
          display: "flex",
          justifyContent: "center",
          color: "#c9b377",
        }}
        className="contactHeading"
      >
        Contact
      </h1>

      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ margin: "30px", padding: "0px" }}
          className="mapCol"
        >
          <section className="mapSection">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d122421.72488690865!2d77.16098731619962!3d16.49178877098066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc9ca5f4cbe3503%3A0x3bdd387e440e13ba!2sGudur%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1691997111950!5m2!1sen!2sin"
              width="1300"
              height="800"
              className="map"
              style={{
                border: "0",

                allowfullscreen: "0",
                loading: "lazy",
                referrerpolicy: "no-referrer-when-downgrade",
              }}
            ></iframe>
          </section>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={24}>
          {/* <section className="formSection">
            <div
              style={{
                boxShadow: " 0px 0px 10px 0px #0000001a",
                padding: "40px",
                paddingTop: "20px",
                paddingBottom: "05px",
              }}
            >
              <h1 className="formSectionHeading">Get in touch</h1>
              <h6 className="formSectionSubHeading">
                Please fill in the details, we will reply back to your email.
              </h6>
              <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                <FormItem
                  className="nameItem"
                  name="fullname"
                  label="Full Name"
                  rules={[
                    { required: true, message: "Please Enter your Name" },
                  ]}
                  hasFeedback
                >
                  <Input
                    className="nameInput"
                    placeHolder="Enter Your Name"
                  ></Input>
                </FormItem>
                <FormItem
                  className="numberItem"
                  name="phoneNumber"
                  label="Phone"
                  rules={[
                    { required: true, message: "Please Enter your Number" },
                  ]}
                  hasFeedback
                >
                  <Input
                    className="numberInput"
                    placeHolder="Enter Your Number"
                  ></Input>
                </FormItem>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a email",
                    },
                    {
                      type: "email",
                      message: "Please enter valid email",
                    },
                  ]}
                  hasFeedback
                >
                  <Input className="formEmail" placeholder="Enter Your Email" />
                </Form.Item>
                <Form.Item
                  className="formReason"
                  name="reason"
                  label="Reason"
                  requiredMark="optional"
                >
                  <Select className="formOption">
                    <Select.Option value="Enquiry">Enquiry</Select.Option>
                    <Select.Option value="Option">Option</Select.Option>
                  </Select>
                </Form.Item>
                <FormItem label="Description">
                  <TextArea className="formTextArea"></TextArea>
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "120px",
                    }}
                  >
                    Submit
                  </Button>
                </FormItem>
              </Form>
            </div>
          </section> */}
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={10}
          xl={10}
          style={{ marginLeft: "20px" }}
        >
          <div className="contactOutlined">
            <ContactsOutlined />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginLeft: "80px",
            }}
          >
            <h1 className="address">Address</h1>
            <h3 className="ownerName">Pujari & Family</h3>
            <p className="phoneOutlinedHphNo">Farm: Gudur</p>
            <p className="phoneOutlinedHphNo">Yadgir, Karnataka</p>
            <p className="phoneOutlinedHphNo">PIN:585221</p>
            <p className="phoneOutlinedHphNo">India</p>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          style={{ marginLeft: "60px" }}
        >
          <section className="phoneNumberSection">
            <div className="phoneOutlined">
              <PhoneOutlined />
            </div>
            <div>
              <h1 className="phoneOutlinedHeading">Customer Care Executive</h1>
              <p className="phoneOutlinedHphNo">+91 9035569995</p>
            </div>
          </section>

          <section className="emailSection">
            <div className="emailOutlined">
              <MailOutlined />
            </div>
            <div>
              <h1 className="email">Email</h1>
              <p className="gmail">pujariandfamily@gmail.com</p>
            </div>
          </section>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="whatsapp">
            <a href=" http://wa.me/919035569995">
              <div className="whatsAppDiv">
                <div>
                  <WhatsAppOutlined
                    style={{
                      marginLeft: "50px",
                      color: "rgb(98, 8, 8)",
                      fontSize: "50px",
                    }}
                  />{" "}
                </div>{" "}
                <div>
                  {" "}
                  <h2 style={{ color: "black", marginLeft: "30px" }}>
                    WhattsApp
                  </h2>
                </div>{" "}
              </div>
              <a
                href="http://wa.me/919035569995"
                style={{
                  fontSize: "20px",
                  marginLeft: "130px",
                  marginBottom: "50px",
                }}
              >
                Connect Via WhattsApp
              </a>

              {/* <img src="https://images.news18.com/ibnlive/uploads/2021/09/whatsapp-1.jpg" alt="" height="50px" width="50px"/> */}
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;

import React from "react";
import { Row, Col } from "antd";
import jeevamruthaPic from "./Images/jeevamrutha.jpg";
import WermiCompostPic from "./Images/WermiCompost.jpg";
import planting from "./Images/Planting.jpg";
import PaddyPlanting from "./Images/PaddingPlanting.jpg";
import vermiComposite from "./Images/Vermicompost.jpeg";
import VermiCompost from "../src/Images/Compost.jpeg";
import owdc from "../src/Images/OWDC.jpeg";

import Father from "./Images/Father.jpg";
import Mother from "./Images/Mother.jpg";
import "./global.css";
import Jeevamruthaprepration from "./Images/Jeevamruthaprepration.jpg";
const PressAndMedia = () => {
  return (
    <div>
      <div>
        ;
        <h1
          className="libraryHeading"
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "40px",
            color: "#c9b377",
          }}
        >
          Natural Fertilization
        </h1>
        <div style={{ margin: "40px" }}>
          <div>
            <h1
              className="heading1"
              style={{ fontSize: "30px", color: " #c9b377" }}
            >
              Jeevamruta
            </h1>
            <img
              className="Photos"
              src={jeevamruthaPic}
              alt="Jeevamrutha"
              width={"100%"}
            />{" "}
            <p
              className="para1"
              style={{ fontSize: "20px", fontWeight: "normal" }}
            >
              Jeevamrutha is a liquid organic fertilizer and a natural pesticide
              prepared by mixing cow dung and cow urine. It is used in organic
              farming to supply nutrients to soil and also to protect the crop
              from diseases and pests. It also helps in increasing the
              population of earthworms and beneficial microbes in the soil.
            </p>
          </div>
          <div>
            <h1
              className="heading1"
              style={{ fontSize: "30px", color: " #c9b377" }}
            >
              Gana Jeevamruta
            </h1>

            <img
              className="Photos"
              src={WermiCompostPic}
              alt=""
              width={"100%"}
            />
            <img
              className="Photos"
              src={vermiComposite}
              alt=""
              width={"100%"}
            />
            <p
              className="para1"
              style={{ fontSize: "20px", fontWeight: "normal" }}
            >
              Gana Jeevamruta is a natural and organic soil treatment used in
              organic farming to enhance soil health and support crop growth; it
              involves shaping a mixture of cow dung, cow urine, jaggery, pulse
              grain flour, live forest soil, and water into five solid round
              balls, which are then placed at each corner and in the center of
              the farming land to uniformly enrich the soil with organic
              nutrients and beneficial microorganisms.
            </p>
          </div>
          <div>
            <h1
              className="heading1"
              style={{ color: " #c9b377", fontSize: "30px" }}
            >
              Go Krupamruta
            </h1>

            <img
              className="Photos"
              src={Jeevamruthaprepration}
              alt=""
              width={"100%"}
            />

            <p
              className="para3"
              style={{ fontSize: "20px", fontWeight: "normal" }}
            >
              Go Krupamruta" is an organic microbial fertilizer used in
              agriculture. This natural fertilizer is designed to enhance soil
              health and stimulate plant growth. It consists of beneficial
              microorganisms and organic materials that enrich the soil,
              increasing its fertility and creating a favorable environment for
              robust crop development. To prepare "Go Krupamruta," you typically
              mix 2 kilograms of jaggery with 2 liters of "Go Kruapmruta"
              culture and 200 liters of water. This organic fertilizer is
              applied to the soil, where the beneficial microbes work to improve
              nutrient availability, support plant growth, and enhance overall
              soil quality in organic farming practices.
            </p>
          </div>
          <div>
            <h1
              className="heading1"
              style={{ color: " #c9b377", fontSize: "30px" }}
            >
              OWDC
            </h1>

            {/* <img
              className="Photos"
              src="https://www.gokulkrishna.com/wp-content/uploads/2020/11/OWDC-1.jpg"
              alt=""
              width={"100%"}
            /> */}

            <p
              className="para1"
              style={{ fontSize: "20px", fontWeight: "normal" }}
            >
              To prepare "OWDC" (Original Waste Decomposer), you typically mix 2
              kilograms of jaggery with 150 milliliters of OWDC and 200 liters
              of water. This mixture is commonly used in agriculture and waste
              management to promote the decomposition of organic materials,
              which can be beneficial for creating compost and improving soil
              health. The microbial culture present in OWDC helps break down
              organic matter, facilitating recycling and nutrient release for
              plants.
            </p>
          </div>

          <h1
            className="heading1"
            style={{
              display: "flex",
              justifyContent: "center",
              color: " #c9b377",
            }}
          ></h1>
          <Row>
            <Col span={24}>
              <div>
                <h1
                  className="heading1"
                  style={{
                    color: " #c9b377",
                  }}
                >
                  VermiCompost
                </h1>
                <img
                  className="family"
                  src={VermiCompost}
                  width={"100%"}
                  alt=""
                />
                <p className="para3" style={{ fontSize: "20px!important" }}>
                  Vermicompost is a nutrient-rich organic fertilizer and soil
                  conditioner produced through the process of vermicomposting.
                  Vermicomposting involves using earthworms (usually red
                  wigglers or Eisenia fetida) to break down organic matter, such
                  as kitchen scraps, yard waste, and other biodegradable
                  materials, into a dark, crumbly, and humus-rich material known
                  as vermicompost. The process of vermicomposting is highly
                  efficient and environmentally friendly. Earthworms feed on the
                  organic matter, breaking it down into simpler compounds, and
                  their digestive processes enhance nutrient availability and
                  microbial activity in the resulting vermicompost. The end
                  product is a nutrient-packed soil amendment that can be used
                  to improve soil structure, water retention, and nutrient
                  content in gardens, agricultural fields, and potted plants.
                  Vermicompost is valued for its ability to: Enhance soil
                  fertility: Vermicompost is rich in essential nutrients like
                  nitrogen, phosphorus, potassium, and micronutrients, making it
                  an excellent natural fertilizer. Improve soil structure: It
                  helps to improve soil texture, aeration, and drainage, making
                  it ideal for both sandy and clayey soils. Boost microbial
                  activity: Vermicompost contains beneficial microorganisms that
                  can help suppress harmful pathogens and promote healthy soil
                  ecosystems. Increase water retention: The organic matter in
                  vermicompost helps soils retain moisture, reducing the need
                  for frequent watering. Reduce waste: Vermicomposting is a
                  sustainable way to recycle kitchen scraps and organic waste,
                  reducing the amount of material sent to landfills.
                </p>
              </div>
            </Col>
            {/* <Col span={24}>
              <div>
                <img
                  className="family"
                  src={Father}
                  style={{ width: "100%", height: "1200px" }}
                  alt=""
                />
              </div>
            </Col>

            <Col span={24}>
              <div>
                <img
                  className="family"
                  style={{ width: "100%", height: "1200px" }}
                  src={Mother}
                  alt=""
                />
              </div>
            </Col> */}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PressAndMedia;

import React from "react";
import { Carousel } from "antd";
import jeevamruthaPic from "./Images/jeevamrutha.jpg";
import WermiCompostPic from "./Images/WermiCompost.jpg";
import planting from "./Images/Planting.jpg";
import PaddyPlanting from "./Images/PaddingPlanting.jpg";
import Father from "./Images/Father.jpg";
import Mother from "./Images/Mother.jpg";
import Jeevamruthaprepration from "./Images/Jeevamruthaprepration.jpg";
import Paddy from "./Images/paddyGrown.jpeg";
import vermiCompost from "./Images/Vermicompost.jpeg";
import crop from "./Images/Crop.jpeg";

import "./SliderComponent.css";

const SliderComponent = () => {
  const images = [
    PaddyPlanting,
    jeevamruthaPic,
    vermiCompost,
    crop,
    "https://thumbs.dreamstime.com/b/paddy-field-11870763.jpg",

    // Add more image URLs and text here
  ];

  return (
    <div>
      {/* <h1>Pujari And Family</h1> */}
      <Carousel autoplay>
        {images.map((image, index) => (
          <div key={index} className="slider-item">
            <img src={image} alt={`Slide ${index}`} className="slider-image" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SliderComponent;

import { Col, Row } from "antd";
import React from "react";
import certificate from "../src/Images/Fssai license-1.jpg";
import certificate2 from "../src/Images/Fssai license-2.jpg";
import certificate3 from "../src/Images/Fssai license-3.jpg";
import certificate4 from "../src/Images/Fssai license-4.jpg";
import Rice1 from "../src/Images/Ricereportpage 1.jpg";
import Rice2 from "../src/Images/ricereportpage2.jpg";
import ChemicalReport1 from "../src/Images/CHEMICAL REPORT 1.jpg";
import ChemicalReport2 from "../src/Images/CHEMICAL REPORT 2.jpg";
import ChemicalReport3 from "../src/Images/CHEMICAL REPORT 3.jpg";
import ChemicalReport4 from "../src/Images/CHEMICAL REPORT4.jpg";
import "../src/Certificate.css";
const Certificate = () => {
  return (
    <div>
      <h1
        className="certificateHeading"
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "50px",
          color: "#c9b377",
          fontFamily: "monospace",
        }}
      >
        Certificates
      </h1>

      <div>
        <h3
          className="FssaiHeading"
          style={{ marginLeft: "100px", fontSize: "30px", color: "#c9b377" }}
        ></h3>
        <Row justify={"center"}>
          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={Rice1}
            ></img>
          </Col>
          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={Rice2}
            ></img>
          </Col>
        </Row>
      </div>
      <div>
        <h3
          className="FssaiHeading"
          style={{ marginLeft: "100px", fontSize: "30px", color: "#c9b377" }}
        ></h3>
        <Row justify={"center"}>
          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={ChemicalReport1}
            ></img>
          </Col>
          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={ChemicalReport2}
            ></img>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={ChemicalReport3}
            ></img>
          </Col>
          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={ChemicalReport4}
            ></img>
          </Col>
        </Row>
      </div>
      <div>
        <h1
          className="FssaiHeading"
          style={{ marginLeft: "100px", fontSize: "30px", color: "#c9b377" }}
        ></h1>
        <Row justify={"center"}>
          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={certificate}
            ></img>
          </Col>

          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={certificate2}
            ></img>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={certificate3}
            ></img>
          </Col>
          <Col lg={10} xs={24} style={{ margin: "20px" }}>
            <img
              className="images"
              style={{ width: "600px", height: "720px", margin: "20px" }}
              src={certificate4}
            ></img>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Certificate;
